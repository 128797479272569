import React, { useEffect, useMemo, useState } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  /* Link, */ useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { useTheme } from "react-jss";
import LogoImg from "../../assets/images/agua_logo.png";
import CustomTypography from "../CustomTypography";
import MenuButton from "./MenuButton";
import useStyles from "./styles";
import ThemeButton from "./ThemeButton";
import TranslationButton from "./TranslationButton";
import { breakpoints } from "../../constants/constraints";
import api from "../../services/api";
import { defaults } from "../../constants/general";

/**
 * This functional component renders the application header, that will allows
 * the platform navigation, theming, translation and other options.
 * @returns The header component.
 */
function Header() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const smScreen = useMediaQuery(breakpoints.min.sm);
  const mdScreen = useMediaQuery(breakpoints.min.md);
  const location = useLocation();
  const theme = useTheme();
  const params = useParams();
  const [inititiveOptions, setInitiativeOptions] = useState();
  const [hasWater, setHasWater] = useState();
  const [hasGlacier, setHasGlacier] = useState();

  // eslint-disable-next-line no-unused-vars
  const handleClickMethod = () => {
    if (location.pathname === "/methodology") {
      history.push("/map");
    } else {
      history.push("/methodology");
    }
  };

  const pathRoute = useMemo(
    () => history.location.pathname.split("/")[1],
    [history.location.pathname]
  );

  useEffect(() => {
    let isSubscribed = true;

    api.get(`/initiatives`).then(({ data }) => {
      if (isSubscribed) {
        setInitiativeOptions(data);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (!inititiveOptions) return () => {};

    let isSubscribed = true;

    const { initiative } = params;
    const territory = inititiveOptions.find(
      (option) => option.type === initiative
    )?.defaultTerritory;

    if (territory) {
      api
        .get(`territories/${territory.type}/${territory.code}/groupings`, {
          params: { dataType: "water", initiative },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setHasWater(Object.keys(data).length > 0);
          }
        });

      api
        .get(`territories/${territory.type}/${territory.code}/groupings`, {
          params: { dataType: "glacier", initiative },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setHasGlacier(Object.keys(data).length > 0);
          }
        });
    } else {
      setHasWater(false);
      setHasGlacier(false);
    }

    return () => {
      isSubscribed = false;
    };
  }, [inititiveOptions]);

  useEffect(() => {
    if (!inititiveOptions) return;

    const [dataType] = location.pathname.split("/").slice(1, 3);

    if (dataType === "water" && !hasWater) {
      window.location.href = `${defaults[dataType].initiative}`;
    }
  }, [hasWater]);

  useEffect(() => {
    if (!inititiveOptions) return;

    const [dataType] = location.pathname.split("/").slice(1, 3);

    if (dataType === "glacier" && !hasGlacier) {
      window.location.href = `${defaults[dataType].initiative}`;
    }
  }, [hasGlacier]);

  return (
    <div className={classes.container}>
      {/* <Link to="/map"> */}
      <img src={LogoImg} alt="logo" className={classes.logo} />
      {/* </Link> */}
      <div className={classes.buttonsWrapper}>
        {smScreen && (
          <div className={classes.buttonsSection}>
            {/* <Button
              className={classes.button}
              target="_blank"
              href="https://mapbiomas.org/"
            >
              <CustomTypography weight="bold" className={classes.buttonText}>
                {t("header.buttons.mapbiomas")}
              </CustomTypography>
            </Button> */}
            {/* <div className={classes.separator} />
            <Button onClick={handleClickMethod} className={classes.button}>
              <CustomTypography weight="bold" className={classes.buttonText}>
                {location.pathname === "/methodology"
                  ? t("header.buttons.map")
                  : t("header.buttons.method")}
              </CustomTypography>
            </Button> */}
            {/* <div className={classes.separator} /> */}
            {/* {params?.initiative === "brazil" && (
              <Button
                onClick={() =>
                  window
                    .open(
                      `${process.env.REACT_APP_MONTHLY_STATISTICS_URL}`,
                      "_blank"
                    )
                    .focus()
                }
                className={classes.button}
              >
                <CustomTypography weight="bold" className={classes.buttonText}>
                  {t("header.buttons.monthlyStatistics")}
                </CustomTypography>
              </Button>
            )} */}
            <Button
              onClick={() =>
                window
                  .open(`${process.env.REACT_APP_API_URL}docs`, "_blank")
                  .focus()
              }
              className={classes.button}
            >
              <CustomTypography weight="bold" className={classes.buttonText}>
                {t("header.buttons.api")}
              </CustomTypography>
            </Button>
            <div className={classes.separator} />
            {hasWater && (
              <Button
                onClick={() => history.push(`/water/${params.initiative}`)}
                className={
                  pathRoute === "water" ? classes.activedButton : classes.button
                }
              >
                <CustomTypography
                  weight="bold"
                  className={classes.buttonText}
                  style={pathRoute === "water" ? { color: theme.primary } : {}}
                >
                  {t("header.buttons.water")}
                </CustomTypography>
              </Button>
            )}
            {hasGlacier && (
              <Button
                onClick={() => history.push(`/glacier/${params.initiative}`)}
                className={
                  pathRoute === "glacier"
                    ? classes.activedButton
                    : classes.button
                }
              >
                <CustomTypography
                  weight="bold"
                  className={classes.buttonText}
                  style={
                    pathRoute === "glacier" ? { color: theme.primary } : {}
                  }
                >
                  {t("header.buttons.glacier")}
                </CustomTypography>
              </Button>
            )}
            {params?.initiative === "brazil" && (
              <Button
                onClick={() =>
                  window
                    .open(`${process.env.REACT_APP_BASINS_URL}`, "_blank")
                    .focus()
                }
              >
                <CustomTypography weight="bold" className={classes.buttonText}>
                  {t("header.buttons.basins")}
                </CustomTypography>
              </Button>
            )}
          </div>
        )}
        <div className={classes.buttonsSection}>
          {mdScreen && (
            <>
              <TranslationButton />
              <div className={classes.separator} />
              <ThemeButton />
              {/* <div className={classes.separator} /> */}
            </>
          )}
          {!mdScreen && (
            <MenuButton hasGlacier={hasGlacier} hasWater={hasWater} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
